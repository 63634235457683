import React from "react";
import "./App.scss";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Accueil from "./components/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

function App (){
 
  const [theme, setTheme] =  useState(
    localStorage.getItem('theme') || 'dark');

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

  return (
    <body className={`App ${theme}`}>
     <div>
      <Button
        className="rounded-circle btn-switch"
        onClick={toggleTheme}></Button>
        <Accueil />
      </div>
    </body>
  );
}

export default App;