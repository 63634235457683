import "../App.scss";
import {Container} from "react-bootstrap";



function Accueil() {

  return (
    <>
     <Container fluid className="h-100 bg-primary">
         

    </Container>

      </>
  );
}

export default Accueil;